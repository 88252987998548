html#iubenda_policy,
#iubenda_policy body {
  margin: 0;
  padding: 0;
}

html#iubenda_policy {
  overflow-y: scroll;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
#iubenda_policy {
  background: #fff;
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  dd,
  dl,
  dt,
  li,
  ol,
  ul,
  fieldset,
  form,
  label,
  legend,
  button,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: normal;
    font-style: normal;
    font-size: 100%;
    line-height: 1;
    font-family: inherit;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  ol,
  ul {
    list-style: none;
  }

  q:before,
  q:after,
  blockquote:before,
  blockquote:after {
    content: '';
  }
  a:focus {
    outline: thin dotted;
  }
  a:hover,
  a:active {
    outline: 0;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  nav,
  section {
    display: block;
  }
  audio,
  canvas,
  video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
  }
  audio:not([controls]) {
    display: none;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
}

#iubenda_policy sup {
  top: -0.5em;
}
#iubenda_policy sub {
  bottom: -0.25em;
}
#iubenda_policy img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}
#iubenda_policy button,
#iubenda_policy input,
#iubenda_policy select,
#iubenda_policy textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}
#iubenda_policy button,
#iubenda_policy input {
  line-height: normal;
  *overflow: visible;
}
#iubenda_policy button::-moz-focus-inner,
#iubenda_policy input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
#iubenda_policy button,
#iubenda_policy input[type='button'],
#iubenda_policy input[type='reset'],
#iubenda_policy input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button;
}
#iubenda_policy input[type='search'] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
#iubenda_policy input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
#iubenda_policy textarea {
  overflow: auto;
  vertical-align: top;
}
html#iubenda_policy {
  -webkit-font-smoothing: antialiased;
}
#iubenda_policy p {
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  margin-bottom: 9px;
}
#iubenda_policy p small {
  font-size: 11px;
  color: #bfbfbf;
}
#iubenda_policy h1,
#iubenda_policy h2,
#iubenda_policy h3,
#iubenda_policy h4,
#iubenda_policy h5,
#iubenda_policy h6 {
  font-weight: bold;
  color: #505762;
}
#iubenda_policy h1 {
  margin-bottom: 18px;
  font-size: 30px;
  line-height: 2;
}
#iubenda_policy h1 small {
  font-size: 18px;
}
#iubenda_policy h2 {
  font-size: 24px;
  margin-bottom: 18px;
  line-height: 1.5;
}
#iubenda_policy h2 small {
  font-size: 14px;
}
#iubenda_policy h3,
#iubenda_policy h4,
#iubenda_policy h5,
#iubenda_policy h6 {
  margin-bottom: 9px;
}
#iubenda_policy h3 {
  font-size: 18px;
}
#iubenda_policy h3 small {
  font-size: 14px;
}
#iubenda_policy h4 {
  font-size: 16px;
}
#iubenda_policy h4 small {
  font-weight: bold;
  font-size: 13px;
}
#iubenda_policy h5 {
  font-size: 13px;
  padding-top: 19px;
}
#iubenda_policy h6 {
  font-size: 13px;
  color: #bfbfbf;
  text-transform: uppercase;
}
#iubenda_policy ul ul,
#iubenda_policy ul ol,
#iubenda_policy ol ol,
#iubenda_policy ol ul {
  margin: 0;
}
#iubenda_policy ul.styled,
#iubenda_policy ul {
  list-style: disc;
  padding-top: 5px;
}
#iubenda_policy ul.styled li,
#iubenda_policy ul li {
  list-style: disc;
  line-height: 19px;
  font-size: 13px;
  margin-left: 30px;
  margin-top: 2px;
}
#iubenda_policy ol {
  list-style: decimal;
}
#iubenda_policy ul.unstyled {
  list-style: none;
  margin-left: 0;
}
#iubenda_policy dl {
  margin-bottom: 18px;
}
#iubenda_policy dl dt,
#iubenda_policy dl dd {
  line-height: 18px;
}
#iubenda_policy dl dt {
  font-weight: bold;
}
#iubenda_policy dl dd {
  margin-left: 9px;
}
#iubenda_policy hr {
  margin: 0 0 19px;
  border: 0;
  border-bottom: 1px solid #eee;
}
#iubenda_policy strong {
  font-style: inherit;
  font-weight: bold;
}
#iubenda_policy em {
  font-style: italic;
  font-weight: inherit;
  line-height: inherit;
}
#iubenda_policy .muted {
  color: #bfbfbf;
}
#iubenda_policy blockquote {
  margin-bottom: 18px;
  border-left: 5px solid #eee;
  padding-left: 15px;
}
#iubenda_policy blockquote p {
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  margin-bottom: 0;
}
#iubenda_policy blockquote small {
  display: block;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: #bfbfbf;
}
#iubenda_policy blockquote small:before {
  content: '\2014 \00A0';
}
#iubenda_policy address {
  display: block;
  line-height: 18px;
  margin-bottom: 18px;
}
#iubenda_policy code,
#iubenda_policy pre {
  padding: 0 3px 2px;
  font-family:
    Monaco,
    Andale Mono,
    Courier New,
    monospace;
  font-size: 12px;
  border-radius: 3px;
}
#iubenda_policy code {
  background-color: #fee9cc;
  color: rgba(0, 0, 0, 0.75);
  padding: 1px 3px;
}
#iubenda_policy pre {
  background-color: #f5f5f5;
  display: block;
  padding: 17px;
  margin: 0 0 18px;
  line-height: 18px;
  font-size: 12px;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
#iubenda_policy .breadcrumbs {
  padding: 0 0 10px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid #f6f6f6;
  width: 100%;
}
#iubenda_policy .breadcrumbs > li {
  float: left;
  filter: alpha(opacity= '$opacity');
  -khtml-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
}
#iubenda_policy .breadcrumbs > li:not(:last-child):after {
  color: #333b43;
  padding: 0 10px;
  content: '\203a';
}
#iubenda_policy .breadcrumbs + .pills,
#iubenda_policy .breadcrumbs + .sec_tabs {
  margin-top: -15px;
}
#iubenda_policy .table {
  display: table;
  border-collapse: collapse;
  padding: 0 !important;
  margin: 0;
}
#iubenda_policy .cust_row {
  display: table-row;
  margin: 0;
}
#iubenda_policy .column {
  display: table-cell;
  vertical-align: top;
  padding: 30px;
}
#iubenda_policy .box_primary {
  border: 1px solid #c0c1c1;
  border-bottom-color: #a8aaab;
  -webkit-box-shadow: 0 1px 0 #ebebec;
  box-shadow: 0 1px 0 #ebebec;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}
#iubenda_policy .box_content {
  border-radius: 4px;
  padding: 30px;
}
#iubenda_policy .box_content .iub_content {
  padding: 30px;
}
#iubenda_policy .box_content .iub_content > hr {
  width: 686px;
  margin-left: -30px;
  margin-right: -30px;
}
#iubenda_policy .box_content .aside {
  width: 191px;
  padding: 30px;
}
#iubenda_policy .box_content .aside.aside-right {
  border-left: 1px solid #dfdfdf;
}
#iubenda_policy .table > .box_content {
  padding: 0;
}
#iubenda_policy .box_10 {
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 15px;
}
#iubenda_policy .box_10 > h4 {
  margin-bottom: 0;
  font-size: 13px;
}
#iubenda_policy .box_10 > .w_icon,
#iubenda_policy .box_10 > .w_icon.expand-click,
#iubenda_policy .box_10.expand > .w_icon,
#iubenda_policy .box_10.expand > .w_icon.expand-click {
  padding-left: 45px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position-x: 10px;
  background-position-y: 7px;
  background-position: 10px 7px;
}
#iubenda_policy .box_10 > .w_icon_16,
#iubenda_policy .box_10 > .w_icon_16.expand-click,
#iubenda_policy .box_10.expand > .w_icon_16,
#iubenda_policy .box_10.expand > .w_icon_16.expand-click {
  padding-left: 40px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position-x: 11px;
  background-position-y: 11px;
  background-position: 11px 11px;
}
#iubenda_policy .box_10 > .w_icon_24,
#iubenda_policy .box_10 > .w_icon_24.expand-click,
#iubenda_policy .box_10.expand > .w_icon_24,
#iubenda_policy .box_10.expand > .w_icon_24.expand-click {
  padding-left: 45px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position-x: 10px;
  background-position-y: 10px;
  background-position: 10px 10px;
}
#iubenda_policy .box_5 {
  padding: 5px;
  border-radius: 3px;
  font-size: 11px;
  margin-bottom: 15px;
}
#iubenda_policy .box_5 hr {
  padding-top: 5px;
  margin: 0 -5px 5px -5px;
  border: 0;
  border-bottom: 1px solid #ac3737;
}
#iubenda_policy .box_5.w_icon_16 {
  padding-left: 30px;
  background-repeat: no-repeat;
  background-position-x: 8px;
  background-position-y: 6px;
  background-position: 8px 6px;
}
#iubenda_policy .box_5.w_icon_16 hr {
  width: 100%;
  padding-left: 30px;
  padding-right: 5px;
  margin-left: -30px;
  margin-right: -5px;
}
#iubenda_policy .box_5.w_icon_16.red {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAATlBMVEUAAAD%2F%2F%2F8AAAD%2F%2F%2F8AAAAAAAD%2F%2F%2F%2F%2F%2F%2F%2FT09P%2F%2F%2F%2F9%2Ff3Y2Nj9%2Ff39%2Ff3d3d3%2F%2F%2F%2F8%2FPz39%2Ff19fX%2B%2Fv79%2Ff34%2BPj5%2Bfn8%2FPz9%2Ff3%2F%2F%2F8ZO4GEAAAAGXRSTlMAEB0gMDNAUHSAgYSRoaWwsra3weLl5fLyUJhrdwAAAF1JREFUeF6NzUcWhCAAwFAQsIPOWCD3v6gPxLYjy7%2BJKE1Ok%2FxAD%2BMbFIB6wYIxLA%2FUbEJAc8PKHmG9oAOkArq8DICdgXCuLUA7EDkBsd%2BfWALnyXmXoNImpytR0AEwdQcUE5t8VQAAAABJRU5ErkJggg%3D%3D);
}
#iubenda_policy .box_thumb {
  background: #fff;
  -webkit-box-shadow:
    0 0 1px #a3a3a3,
    0 1px 1px #a3a3a3;
  box-shadow:
    0 0 1px #a3a3a3,
    0 1px 1px #a3a3a3;
  padding: 6px;
}
#iubenda_policy footer {
  margin-top: 17px;
  padding-top: 17px;
  border-top: 1px solid #eee;
}
#iubenda_policy hr {
  padding-top: 15px;
  margin: 0 0 15px 0;
}
#iubenda_policy hr.primary {
  border: 0;
  border-bottom: 1px solid #dfdfdf;
  -webkit-box-shadow: 0 1px 0 #f7f7f7;
  box-shadow: 0 1px 0 #f7f7f7;
}
#iubenda_policy .btn {
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.08);
  padding: 0 20px;
  line-height: 38px;
  color: #54616b;
  font-size: 13px;
  border: 0;
  border-radius: 4rem;
  border-collapse: separate;
  -webkit-transition: 0.1s linear all;
  transition: 0.1s linear all;
}
#iubenda_policy .btn:hover {
  background-position: 0 -15px;
  text-decoration: none;
}
#iubenda_policy .btn:focus {
  outline: 1px dotted #666;
}
#iubenda_policy .btn:active {
  -webkit-box-shadow:
    inset 0 2px 4px rgba(0, 0, 0, 0.25),
    0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow:
    inset 0 2px 4px rgba(0, 0, 0, 0.25),
    0 1px 2px rgba(0, 0, 0, 0.05);
}
#iubenda_policy .btn.disabled {
  cursor: default;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  filter: alpha(opacity= '$opacity');
  -khtml-opacity: 0.65;
  -moz-opacity: 0.65;
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#iubenda_policy .btn[disabled] {
  cursor: default;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  filter: alpha(opacity= '$opacity');
  -khtml-opacity: 0.65;
  -moz-opacity: 0.65;
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#iubenda_policy .btn.large {
  font-size: 16px;
  line-height: normal;
  padding: 0 14px;
  border-radius: 6px;
}
#iubenda_policy .btn.mid_large {
  padding: 0 10px;
  line-height: 32px;
  font-size: 13px;
}
#iubenda_policy .btn.mid {
  padding: 0 10px;
  line-height: 28px;
  font-size: 11px;
}
#iubenda_policy .btn.small {
  padding: 0 8px;
  line-height: 18px;
  font-size: 11px;
}
#iubenda_policy :root .alert-message,
#iubenda_policy :root .btn {
  border-radius: 0 \0;
}
#iubenda_policy button.btn::-moz-focus-inner,
#iubenda_policy input.btn[type='submit']::-moz-focus-inner {
  padding: 0;
  border: 0;
}
#iubenda_policy .circle {
  font-size: 11px;
  line-height: 18px;
  width: 18px;
  padding: 0;
  text-align: center;
  border-radius: 11px;
}
#iubenda_policy .circle.small {
  width: 14px;
  line-height: 14px;
  border-radius: 9px;
  padding: 0;
}
#iubenda_policy .blue,
#iubenda_policy .btn.primary {
  color: #fff;
  background-color: #0073ce;
}
#iubenda_policy .yellow {
  color: #6d693d;
  background-color: #ffd24d;
}
#iubenda_policy .red {
  color: #fff;
  background-color: #ff5d4d;
}
#iubenda_policy .red a,
#iubenda_policy .red a:hover:not(.btn) {
  color: #fff;
}
#iubenda_policy .red a {
  border-bottom-color: rgba(247, 247, 247, 0.3);
}
#iubenda_policy .red a:hover {
  border-bottom-color: rgba(247, 247, 247, 0.6);
}
#iubenda_policy .green {
  color: #4d6c47;
  background-color: #f1ffd5;
}
#iubenda_policy .iubgreen {
  color: #01281b;
  background-color: #1cc691;
}
#iubenda_policy .azure {
  color: #364048;
  background-color: #d2ecfe;
}
#iubenda_policy .white {
  color: #54616b;
  background-color: #f8f8f8;
}
#iubenda_policy .black {
  color: #fff;
  background-color: #333333;
}
#iubenda_policy .trasp {
  color: #333b43;
  background-color: #ffffff;
}
#iubenda_policy .alert-message {
  position: relative;
  padding: 7px 15px;
  margin-bottom: 18px;
  color: #404040;
  background-color: #eedc94;
  background-repeat: repeat-x;
  background-image: -khtml-gradient(linear, left top, left bottom, from(#fceec1), to(#eedc94));
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fceec1), to(#eedc94));
  background-image: linear-gradient(to bottom, #fceec1, #eedc94);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$startColor', endColorstr='$endColor', GradientType=0);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  border-color: #eedc94 #eedc94 #e4c652;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) fadein(rgba(0, 0, 0, 0.1), 15%);
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
}
#iubenda_policy .alert-message .close {
  *margin-top: 3px;
}
#iubenda_policy .alert-message h5 {
  line-height: 18px;
}
#iubenda_policy .alert-message p {
  margin-bottom: 0;
}
#iubenda_policy .alert-message div {
  margin-top: 5px;
  margin-bottom: 2px;
  line-height: 28px;
}
#iubenda_policy .alert-message .btn {
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
}
#iubenda_policy .alert-message.block-message {
  background-image: none;
  background-color: #fdf5d9;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  padding: 14px;
  border-color: #fceec1;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#iubenda_policy .alert-message.block-message ul,
#iubenda_policy .alert-message.block-message p {
  margin-right: 30px;
}
#iubenda_policy .alert-message.block-message ul {
  margin-bottom: 0;
}
#iubenda_policy .alert-message.block-message li {
  color: #404040;
}
#iubenda_policy .alert-message.block-message .alert-actions {
  margin-top: 5px;
}
#iubenda_policy .alert-message.block-message.error,
#iubenda_policy .alert-message.block-message.success,
#iubenda_policy .alert-message.block-message.info {
  color: #404040;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
#iubenda_policy .alert-message.block-message.error {
  background-color: #fddfde;
  border-color: #fbc7c6;
}
#iubenda_policy .alert-message.block-message.success {
  background-color: #d1eed1;
  border-color: #bfe7bf;
}
#iubenda_policy .alert-message.block-message.info {
  background-color: #ddf4fb;
  border-color: #c6edf9;
}
#iubenda_policy .fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  opacity: 0;
}
#iubenda_policy .fade.in {
  opacity: 1;
}
#iubenda_policy .expand-click {
  cursor: pointer;
  position: relative;
}
#iubenda_policy .box_10.expand .expand-click {
  margin: -10px;
  padding: 12px 25px 13px 10px;
}
#iubenda_policy .box_10.expand .expand-content {
  margin-top: 10px;
}
#iubenda_policy .box_10.expand .expand-content > *:first-child {
  margin-top: 0;
  padding-top: 0;
}
#iubenda_policy .expand.expanded .expand-click:after,
#iubenda_policy .box_10.expand.expanded .expand-click:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 19px;
  border: 5px;
  border-color: transparent;
  border-style: solid;
  border-top-color: #333b43;
}
#iubenda_policy .expand .expand-click,
#iubenda_policy .expand.expanded .expand-click,
#iubenda_policy .box_10.expand .expand-click,
#iubenda_policy .box_10.expand.expanded .expand-click {
  border-bottom: 1px dotted #ddd;
  margin-bottom: 10px;
  -webkit-transition: 0.2s linear all;
  transition: 0.2s linear all;
}
#iubenda_policy .expand.collapsed .expand-click {
  border-bottom: 0;
  margin-bottom: -10px;
}
#iubenda_policy .expand.collapsed .expand-click:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 17px;
  border: 5px;
  border-color: transparent;
  border-style: solid;
  border-right-color: #333b43;
}
#iubenda_policy .all-collapsed .expand .expand-click:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 17px;
  border: 5px;
  border-color: transparent;
  border-style: solid;
  border-right-color: #333b43;
}
#iubenda_policy .all-collapsed .expand .expand-click {
  border-bottom: 0;
  margin-bottom: -10px;
}
#iubenda_policy .all-collapsed .expand-content {
  display: none;
}
html#iubenda_policy,
#iubenda_policy body {
  background-color: #fff;
}
#iubenda_policy {
  font-family: 'Helvetica Neue', Helvetica, Arial, FreeSans, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  color: #505762;
}
#iubenda_policy body {
  margin: 0;
}
#iubenda_policy .iub_container-fluid {
  position: relative;
  min-width: 940px;
  padding-left: 20px;
  padding-right: 20px;
  zoom: 1;
}
#iubenda_policy .iub_container-fluid:before,
#iubenda_policy .iub_container-fluid:after {
  display: table;
  content: '';
  zoom: 1;
  *display: inline;
}
#iubenda_policy .iub_container-fluid:after {
  clear: both;
}
#iubenda_policy .iub_container-fluid > .sidebar {
  float: left;
  width: 220px;
}
#iubenda_policy .iub_container-fluid > .iub_content {
  margin-left: 240px;
}
#iubenda_policy a {
  text-decoration: none;
  font-weight: bold;
  border-bottom: 1px solid #f6f6f6;
  color: #333b43;
}
#iubenda_policy a.unstyled {
  border-bottom: 0;
}
#iubenda_policy a:hover:not(.btn) {
  color: #121518;
  border-bottom-color: #d6d6d6;
  -webkit-transition: 0.1s linear all;
  transition: 0.1s linear all;
}
#iubenda_policy a.no_border,
#iubenda_policy a.no_border:hover {
  border-bottom-width: 0;
}
#iubenda_policy .pull-right {
  float: right;
}
#iubenda_policy .pull-left {
  float: left;
}
#iubenda_policy .hide {
  display: none;
}
#iubenda_policy .show {
  display: block;
}
#iubenda_policy .link_on_dark a {
  border-bottom-color: rgba(247, 247, 247, 0.3);
}
#iubenda_policy .link_on_dark a:hover {
  border-bottom-color: rgba(247, 247, 247, 0.6);
}
#iubenda_policy [class*='policyicon_'] {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAGFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz9%2BjSc3AAAAB3RSTlMAEEBQgMzQxeXuPgAAADJJREFUGFdjYMAJWEPhIAAPh70cDgoGK6cI5B8Yp6S8TACJk4gkA5RAcBKR9BQLoAUOAATNYYOCulUNAAAAAElFTkSuQmCC);
}
#iubenda_policy .policyicon_pdt_68 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAKVJREFUeF51jlmWwyAMBGXEboT6%2FqedIZAAJqnfer3QJKpGOrkKakW5noIrAlFA5V0EKL%2B8Iqw1d%2B%2FojflTx4JlNUJGnVe1tOBUfRMZYmjDCDKRINFBglCLnXiltnTClfAtEgACxvHJldHF4xYL3gLq1l1Mgfk5AZtQx%2FYfdroL4TySXFeRWTAQc0%2Fhe0FHbRiicsJGZG3iNgUPiimgYBUHlQP94g9%2BZg8xOTGEFAAAAABJRU5ErkJggg%3D%3D);
}
#iubenda_policy .policyicon_purpose_5 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAElBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2BtTDCxAAAABXRSTlMAECBAgLf%2B2%2BsAAABGSURBVBhXY2AAA5ZQBwY4YA0NIJfjCjYHygkNDUTmBGPhgOyFc1iB6pE4wSAOUAGCIxoaiOCYhgYjOKqhQThkyODAAR4OAI98N9LK6tL3AAAAAElFTkSuQmCC);
}
#iubenda_policy .policyicon_purpose_7 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAINJREFUeF6V0UsOxCAIBmB8tVoZ4f6nnUqaoFUW%2FVeEj0hUMOKM9kE7CBcxr93SuGcCf%2FRZniCmXGVUwZV2M78DgYRXQDaAP0OzIJIB4C%2FaQo%2BTCyK9ISFizimAPyuNACjlKXW6SMF30B9I9YFndRieuZCCHKU0QIU1LDEhrvDrQG6EP%2FDZElAL0vLHAAAAAElFTkSuQmCC);
}
#iubenda_policy .policyicon_purpose_9 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAACC0lEQVQ4y7XSO2gWVhQH8BPxDRG%2BEhurMcSI4GsoPqjkZ6BLwcFFHUQJKqbEwRciDqZDF90cpIsILtZHh0KTIdQMgkTRiIshBoWgTRpbsVaxgqRf4uM4JCHfRzpIwXun8%2Bf%2BuHDOifj%2FxwoD2qek7Qat%2FG9Qr1%2FblLRNv%2FqyqKHCjIgIqw3oGE9mmtlQERGhw4DVERFmNFREhG91uq6gxUspnVdlky5dNqlyXkovtSi4rtPe8JeUaq1yWLN9tkVoklJThK1a7HXISrVSehpSGrXb5woWqFZljZNSOmmtBRapUe0Lu4xKOQZSr0633dejS7chKQ25p0%2BvHn3u6Bt7OQFSeuWG3pI6DbvpZ5dc8WwimwTPbYswx49Sei89sDNCpaoI6%2FyqWA5OmxUR4StF6Z0hX5puvyH%2FOmeeudrLwXfjg1prUCo6FuGyty444W89CpYZKQU%2FmF3ywwvVthtxwpwImz1yzjSdpWBYq2nWuzbWoQgX%2FaPOAd%2Br1O55hDOl4LHdDRXqnPVWehLhlPSNgiURFlof4adJMGC7eRERarRKr32t2qBn9lhlg%2BVq7fDbJDhasp%2BfueW9brOscdULv7vntlselnZpadlKH5fSRYvN16ytdJgT4KBGGzVqtNFmv4yndzWrt8WjqSCNGFZUNOxN2Xq8K6%2FD47Et%2FKg7ajAc9edHgz8ciU9%2BPgBKt4%2FTzlslzAAAAABJRU5ErkJggg%3D%3D);
}
#iubenda_policy .policyicon_purpose_10,
#iubenda_policy .policyicon_purpose_15 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAKVJREFUeF51jlmWwyAMBGXEboT6%2FqedIZAAJqnfer3QJKpGOrkKakW5noIrAlFA5V0EKL%2B8Iqw1d%2B%2FojflTx4JlNUJGnVe1tOBUfRMZYmjDCDKRINFBglCLnXiltnTClfAtEgACxvHJldHF4xYL3gLq1l1Mgfk5AZtQx%2FYfdroL4TySXFeRWTAQc0%2Fhe0FHbRiicsJGZG3iNgUPiimgYBUHlQP94g9%2BZg8xOTGEFAAAAABJRU5ErkJggg%3D%3D);
}
#iubenda_policy .policyicon_purpose_13 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAJ1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz9PhkGkAAAADHRSTlMAECBAUHCQoLDA4PB7ua%2BoAAAAa0lEQVR42p3QQQ6AIAxE0aEIFdr7n1eMxIAOMfEt%2B9sF4IOkYt5YSTKO1Qd6p%2BQP6Zqrvyjd7zdiLJggO5VReajwhR%2FBnDIoDwrhQcAfkhd%2FtQO0KDqf1A0kmEZgDjk2AZPzPoJo6wFEYOsHFFISOn%2BKxfoAAAAASUVORK5CYII%3D);
}
#iubenda_policy .policyicon_purpose_14 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAMFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2Fjai5RAAAAD3RSTlMAECAwUGBwgJCgsMDQ4PASl6hyAAAAfklEQVR42pXRUQ6EMAgE0MEWW21l7n9btanJWnE3%2Bz4hhCHgq5jKooKD6FJS7OVQebIIROOphlY3dqrsLABidJgg0ZWw0bWBL%2F5vvO%2FIdGVM%2Fh0TMNMx47DwYcVJKgdV0MgwUwSXfA%2F0QY2dKW7CxutHA1lbHMFTavE9qsBvOztlFTRVyS%2BYAAAAAElFTkSuQmCC);
}
#iubenda_policy .policyicon_purpose_16 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAM1BMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2F10LmwAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAJFJREFUeF6V0NsOAyEIRVE6I4rFwvn%2Fr63N3CR10nQnPK2IUdpbpKmsorJQqOKTl2xeRhDsycMgA7QDGkmfq9cI%2FvNEhGcAO8CowAbAGTEwX1XDKvYNnJM7f78clVqfydOlgwRIG6S1TwDdQEnD3cv1iWw4f54VQ1qfUO5QDDGYVLNCmOQ5O2Ea8R2kP8FWobvefhoT%2FSVCMbAAAAAASUVORK5CYII%3D);
}
#iubenda_policy .icon_ribbon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAW0lEQVR42u3OwQkAIQxE0XSWVrazlJpdQdGDC0pQEf7A3ELmibsPV1V9pDM%2FAQAAAAAAAAAAAAAAEAXY1%2BcUwCQnITYD6niL2ASo4z3EaoDKf8qNBQHxArgK8ALKMXCw%2Bim7vwAAAABJRU5ErkJggg%3D%3D);
}
#iubenda_policy .icon_owner {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAMFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz%2Fjai5RAAAAD3RSTlMAECAwQGBwgJCgsMDQ4PC8YWy5AAAAiElEQVR42o2QwRKFIAhFIcwyRP7%2Fb3uNlkBv0dkw3jODd4AbPHhNC7xAafqjYBRZOzUa0cHmc9IbiZsefIFtiuQ68RS7FUkNnwTWmRewLE9ewSPh73dfCgJbzxkiRxcrDGJhWVxa5MqYr1HzcLSPRo2ojcoZAcyV2F1MzaPoxIqcP4gGkP5BcAIxQBCQ7o5t3AAAAABJRU5ErkJggg%3D%3D);
}
#iubenda_policy .icon_general {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAGFBMVEUAAAA%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz8%2FPz9%2BjSc3AAAAB3RSTlMAEEBQgMzQxeXuPgAAADJJREFUGFdjYMAJWEPhIAAPh70cDgoGK6cI5B8Yp6S8TACJk4gkA5RAcBKR9BQLoAUOAATNYYOCulUNAAAAAElFTkSuQmCC);
}
#iubenda_policy .icon_temple_24 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABwklEQVR42s3Wu0vDUBjGYS%2BLsbZSSaWgguAFHFztYEmFbp0E%2FwOrgotQFyetOKiTLqKCWCenbl20S8FFERydBLt1KV7QwUGR4094hQymjYpi4SGH9zvf%2BUgCoQ3GmF%2F1dwNq%2FRzHaUwkEn24lP73rEaL%2FwEcZmEcJexiDyfKrG8P0OG9OIDBrCvPKMuh98sDaApiAmWYj8fiqg%2FjSrWy9gbrDlDzEHIwLi9YRieiWMOrakIPvZ4DKHYhjTsYD%2Be48Kqrdwpdnw1I4RAFbCKHHWxhX%2BtjHGFb2ZbynHoKOiNV7x3YrnWLrmFFWqvm6vH7DmK4ho0l5NGGCialoiyvPbZ6Yn4GOHhCFBsoIQSDOTHKStoTVY%2FjZ0Act7CxiiICqGJaqsqK2mOrJ%2F6VARGs6ZA2ZTNyq6yoPZF%2FNWC0xiOaEq9HNOpnQBIG3djFGcLKFsQoO8UOepQl%2FQyIIKtP9BjSylcwghhWlKWR0N4sIp4D1NCKDgR0DSIEGxbCYikLibvH8voWNWMdD6jiEXe4waOye1GdGntc9Qcso8nrDtoxgEFdIcp81INed7CIBWQw%2F00ZnbH42YAXef4RnfNn%2FyreALybXwSLU3v7AAAAAElFTkSuQmCC);
}
#iubenda_policy .icon_box_24 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABv0lEQVR42t3Vz0uTcRzAcUMyWhmWdAgqkKDMiBHt0A%2FYsploddwpO1gk0l%2BQEEQU0iUPgd0LIpCOCh2KkG4GTaI6mqAmWhG0CQ5jfXsdntN4bJPRxS%2B84IFnz%2Ff97Nl4Pg0hhP9qkwT%2BtTKZTCN7uM0qeTrZuc4ltQVs0EqK%2B3xmigc8Z5E39HOI7TUFfHAbJxngNQtM0kdzg5VOp7c4TvKYeeZ4wmXaYgNONHGcW7zjA6Mkqzy%2BZq4zzgxj5DhcGUgxwzSZ9TasEtvFCL%2F4WBm4SKDAPXrZV%2BPGWznFVSYIhMpAliILhEieO5ynMWbjDgZ4xjxlAn%2FiAt0skeMG44TIT8boo51zDPOeEqFSXKAnCrRHd5fgNMN8IrDCFLOUCVB74CsdZBlkL03s5xG%2FCcAGA73M0cIViswyQisn%2BFZPoIdljkWP6AIvKDFIiuV6Al2s8JIciSh0hqNk%2BVHvNwiRNd5ylwNRqKvewNnYv1z0O%2FCQ7xsIFCsDCS4xyhKhwiprhCqmGaIz%2Fm0qxBGu8YoCoYpFntLNwbS3ba3zYDdJhshTohwpMEE%2FbeyoMg%2BqTrQWbvKFSSxDJn5tsqH%2FF0QW2NxzTlYFAAAAAElFTkSuQmCC);
}
#iubenda_policy .icon_tools_24 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABdklEQVR42rXVzyuDcRzA8WU3ctOag0RiO5mEg8MKcaJc%2FAlcdxppKPlZdpeTE0dqxZWaaEnh7DShlYuJbG0f78Nz%2BPj2zfM89Wz1qmff5%2FPdu%2B27tpCINNTfJzySyeQGhkPGg7UljBtrEbxDMOkaYCiKJ8QtgR0cGWt9EMe8l8AIBAOWwCGKxtqMCux5CSRQxyW61QtNo4yCEVhWgRzCboFm5CF4xTVuUYUgZQTOVeAZHV4OeRSfEMMFWoxAVt2%2FQtQ14Gzsx43anEWr5Vx61Eza9Qz%2B%2BXw71S09M6hm1v0GVtXmWCMCa2pz3BpgXc1kAg2w1oW0mjnGRJCBOYjhLshAO96MQCroM3hQMzUMBfkOZvEDUU78BFbUxl5LYBdiqPgJbKmNCUtg2xL48PpTMYVvFFDEPdqCDDwi51xHIVi0%2FAGJoew18IJ957oJFWx6CHx5DSygjgPkUULM8i2qGoFTP4ecgaCEMcshh5FFDYIzRGyBhvoF3n%2ByMxzF1ykAAAAASUVORK5CYII%3D);
}
#iubenda_policy .icon_paper_24 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABYklEQVR42t3VP0vDQBjH8Tq4SKXiWFwcRHARVyspgq9AOjkoLoKTcymk%2BjKKYDcnO6iLKPgHRJ11aVGkgt2M2IiWlobzKzyB4zBwBGvBwIdc7rnjR8ITLqGU6qm%2FC8hms%2Bo39TWgg0fUUJX7PV7RlVo1Qtsm4Ckhl%2BM4A%2BGY%2BQJ8TCYiLmp1m4AHWbyGa%2BxiFEtQMneIY80RTtCyCahJwDpOsYc0FtHGDS5wbjjDp03AM6aRRhIpTGAbH5jBEJIm208UwMcbmuIFHam34KEZ0tYGNgEeXFEUW9jAKvLYRFHjCs8moIEMZpHRxnNwzJrx3Oj%2Fj2a%2BQbT4b%2BDBjcmz6iK9M6LF76I6UnHY%2Fgc%2BythB2YK%2B1tcC%2Ful54COPMeT0tsMBxsW%2B0dY52VPAe1RAgAoGw7OA8QoUbvVzgPEU7qS2HJ4b33tRQfBTQBcl4wBZgMIlhrX5EVxJbd7YU0JXD%2BipLw8sG8DBrCLzAAAAAElFTkSuQmCC);
}
#iubenda_policy .icon_man_24 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABLUlEQVR42t3VsUrDUBSAYaWtSwSp6NAH6CbOVSHZ9QEEBx9AEPc6OAh9Bp0KDg6igqs4tktXFQQnKQiKiNRAp%2BT4C2cImqbXc3Fp4VsazvlpuUmmRORfTUig6BNFURnbeISoB2yhlDPiHvhegF18Qn74wA5KPoE13EFGuEXDFGBwBoeQMQ5QsQSqOHUInGDOEljElUPgEguWwDzOHQJnqFoCszh2CBwhsAQq2EdSsDxFE2XrMV3HS0HgFRs%2B90ENNwWBa9TMgTAMp%2FWR8J6z%2FA2bXo8K%2FRXLuB9xFy%2BZAwwHWMUF4pxArNdWnE%2BRLm2ghS76SMecoj66aOls8CvAl3XsoYNnDCF%2FNNTZju6qZwO93L%2FBLkYvGxggVeIpVYNsoI0nJBBPie5qT8ZL%2FwsLLryq%2FnnTDAAAAABJRU5ErkJggg%3D%3D);
}
#iubenda_policy .icon_keyhole_24 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABIUlEQVR42rXUP0vCQRjA8bIoiAppLmwQEmehSX5RLTb2Huw9RGODLvUCXG0Kp16ALhENNRjRXLRlIeafJOyevkNu14M89%2FPgMx133%2BF4bkZEpkrdHK8oipIoowuHK2QxGxzgkk1cYgD3R9BEAQlzgMOruMA3xOMZuZDAEToQxRkWrYEHOIiiibQ1IBMEOtixBn4mCLSRtwbqIKIGbrFhDRzgA6I4xpw1kMA5ep6LHa6RCh20ddx4Aq%2FYj2OSF1D3BJ6QjCOQwosn8IbtOAK7%2BPQEvlAcf3ghj1zE4J9HrmApJDCPkvLZ3WMtJLCCmjLNLWRCAmk8KkPWxyHMgQLaSmCIk5BJPkVfCYxQxbI10MBQCTjcYcsaeMcIomhhTwtM1S9g12NLa1YPRQAAAABJRU5ErkJggg%3D%3D);
}
#iubenda_policy .iub_base_container {
  border-radius: 3px;
  background: #fff;
  color: #595858;
  position: relative;
}
#iubenda_policy .iub_base_container > .close {
  background: transparent
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAMAAAAmopZHAAAAw1BMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB4eHh1dXUAAAAAAAAAAAAAAAAAAABfX18AAAAAAAAAAAAAAAAAAAAAAAA2NjYAAAAAAAArKyvJycne3t7X19eFhYWxsbGVlZWsrKzr6%2BvLy8vJycnv7%2B%2Fp6enS0tLi4uL09PTv7%2B%2F8%2FPz7%2B%2Fv4%2BPj39%2FcAAABPT09fX19vb2%2F%2F%2F%2F9S%2BfXQAAAAPHRSTlMAAgMEBQYHCgsMDQ4PEhMWGRobHB8gIiMkJScoKSs0NT1DRUpMWF5gjpOYmaGjpr%2FIys3S1dnZ7vP09vfFQC13AAAA9ElEQVR42oXQZ6%2BCMBiG4aeCAoqiuPceuPes8P7%2FX6Xn5RgBTbw%2BNO3dpG2KH0RcM5JJQ4uLUE2UnSM9HZ1y4r0TM50z%2FTs7ZuyVSysKWJX8DZHeUsg2zUfpU4qY6gBE8xLtl6YAtAnP79Ij8uSdFxMNsHY8lVK67nPgxc4CisQ8yTxiRaBGPvcvu%2BSrAY1vvQHUv51TByqHz3sPFcCef75zbgOpwZUiroMUoFSX0b6sKgDMzjqc1x2Tvznb2wTzppf1P1q1u7PTq55mXVuFT7Va48X%2BRnTbL8YtizMTRqHdH45Gw367YAgEKHoml8%2FnMroC9gCKfVabzD1q%2BwAAAABJRU5ErkJggg%3D%3D)
    no-repeat;
  border: none;
  display: block;
  position: absolute;
  z-index: 110;
  top: -10px;
  right: -9px;
  height: 24px;
  width: 23px;
}
#iubenda_policy p {
  line-height: 19px;
  margin: 0;
  padding-top: 11px;
}
#iubenda_policy address {
  margin: 0;
  line-height: inherit;
  display: inline;
}
#iubenda_policy a {
  font-weight: normal;
  border-bottom: 1px solid #f0f0f0;
}
#iubenda_policy .iub_content {
  position: relative;
  padding: 25px 30px;
  margin: 0 auto;
  border-radius: 3px 3px 0 0;
}
#iubenda_policy #wbars {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}
#iubenda_policy #wbars .horizontal {
  display: none;
}
#iubenda_policy .iub_header {
  border-bottom: 1px dotted #dfdfdf;
  padding-bottom: 25px;
  position: relative;
}
#iubenda_policy .iub_header p {
  margin: 0;
  padding: 0;
}
#iubenda_policy .iub_header img {
  display: block;
  position: absolute;
  top: 5px;
  right: 0;
}
#iubenda_policy h1,
#iubenda_policy h2,
#iubenda_policy h3 {
  color: #262626;
  margin: 0;
}
#iubenda_policy h1 + p,
#iubenda_policy h2 + p,
#iubenda_policy h3 + p {
  padding-top: 5px;
}
#iubenda_policy h1 {
  font-size: 19px;
  font-weight: normal;
  line-height: 23px;
  margin-bottom: 5px;
}
#iubenda_policy h2 {
  font-size: 17px;
  font-weight: bold;
  line-height: 21px;
  padding-top: 21px;
}
#iubenda_policy h3 {
  font-size: 13px;
  line-height: 19px;
  font-weight: bold;
  padding-top: 24px;
}
#iubenda_policy h3 + p {
  padding-top: 0;
}
#iubenda_policy .iconed ul li h3 {
  padding-top: 10px;
  color: #615e5e;
}
#iubenda_policy h4 {
  font-size: 13px;
  font-weight: bold;
  padding-top: 19px;
  margin-bottom: 0;
}
#iubenda_policy h4:first-child {
  padding-top: 0;
}
#iubenda_policy h6 {
  color: #262626;
  text-transform: none;
  padding-top: 19px;
}
#iubenda_policy ul.for_boxes,
#iubenda_policy ul.for_boxes > li,
#iubenda_policy ul.unstyled,
#iubenda_policy ul.unstyled > li {
  list-style: none;
  padding: 0;
  margin: 0;
}
#iubenda_policy ul.for_boxes {
  zoom: 1;
}
#iubenda_policy ul.for_boxes:before,
#iubenda_policy ul.for_boxes:after {
  display: table;
  content: '';
  zoom: 1;
  *display: inline;
}
#iubenda_policy ul.for_boxes:after {
  clear: both;
}
#iubenda_policy .half_col {
  float: left;
  width: 50%;
  zoom: 1;
}
#iubenda_policy .half_col:before,
#iubenda_policy .half_col:after {
  display: table;
  content: '';
  zoom: 1;
  *display: inline;
}
#iubenda_policy .half_col:after {
  clear: both;
}
#iubenda_policy .half_col:nth-child(2n + 1) > * {
  margin-right: 15px;
}
#iubenda_policy .half_col:nth-child(2n) > * {
  margin-left: 15px;
}
#iubenda_policy .half_col + .one_line_col,
#iubenda_policy .half_col + .iub_footer {
  border-top: 1px dotted #dfdfdf;
}
#iubenda_policy .one_line_col {
  zoom: 1;
  float: left;
  width: 100%;
  border-bottom: 1px dotted #dfdfdf;
}
#iubenda_policy .one_line_col:before,
#iubenda_policy .one_line_col:after {
  display: table;
  content: '';
  zoom: 1;
  *display: inline;
}
#iubenda_policy .one_line_col:after {
  clear: both;
}
#iubenda_policy .one_line_col > ul.for_boxes > li {
  float: left;
  width: 50%;
}
#iubenda_policy .one_line_col > ul.for_boxes > li:nth-child(2n + 1) {
  clear: left;
}
#iubenda_policy .one_line_col > ul.for_boxes > li:nth-child(2n + 1) > div {
  margin-right: 15px;
}
#iubenda_policy .one_line_col > ul.for_boxes > li:nth-child(2n) {
  clear: right;
}
#iubenda_policy .one_line_col > ul.for_boxes > li:nth-child(2n) > div {
  margin-left: 15px;
}
#iubenda_policy .one_line_col.wide {
  width: 100%;
}
#iubenda_policy .one_line_col.wide > ul.for_boxes > li {
  clear: both;
  width: 100%;
}
#iubenda_policy .one_line_col.wide > ul.for_boxes > li:nth-child(2n + 1) > div {
  margin-right: 0;
}
#iubenda_policy .one_line_col.wide > ul.for_boxes > li:nth-child(2n) > div {
  margin-left: 0;
}
#iubenda_policy ul.normal_list {
  list-style: disc;
  display: block;
  padding-top: 11px;
  li {
    list-style: disc;
    float: none;
    line-height: 19px;
    margin: 5px 25px;
  }
}
#iubenda_policy .simple_pp > ul > li {
  padding-bottom: 21px;
}
#iubenda_policy .simple_pp > ul > li > ul .iconed {
  padding-left: 40px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position-x: 2px;
  background-position-y: 26px;
  background-position: 2px 26px;
}
#iubenda_policy .simple_pp .for_boxes > .one_line_col > ul.for_boxes {
  margin-top: 0;
}
#iubenda_policy .legal_pp .one_line_col {
  float: none;
  border-top: 0;
  padding-bottom: 21px;
}
#iubenda_policy .legal_pp .one_line_col > ul.for_boxes {
  margin-top: 21px;
}
#iubenda_policy .legal_pp .one_line_col > ul.for_boxes > li:nth-child(2n + 1) {
  clear: left;
  float: left;
}
#iubenda_policy .legal_pp .one_line_col > ul.for_boxes > li:nth-child(2n) {
  float: right;
  clear: right;
}
#iubenda_policy .legal_pp .definitions {
  margin-top: 21px;
  .expand-click.w_icon_24 {
    margin-top: -11px;
    padding: 14px 10px 12px 45px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position-x: 5px;
    background-position-y: 0;
    background-position: 5px 0;
  }
}
#iubenda_policy .legal_pp .definitions .expand-content {
  padding-left: 5px;
  padding-right: 5px;
}
#iubenda_policy .wrap p {
  display: inline-block;
}
#iubenda_policy .iub_footer {
  clear: both;
  position: relative;
  font-size: 11px;

  p {
    font-size: 11px;
    padding: 0;
  }
}
#iubenda_policy .iub_content .iub_footer {
  padding: 24px 0;
}
#iubenda_policy .iub_content .iub_footer p {
  margin: 10px 0;
  clear: both;
}
#iubenda_policy .iub_content .iub_footer .show_comp_link {
  display: block;
  float: right;
}
#iubenda_policy .iub_container > .iub_footer {
  min-height: 21px;
  background-color: #f6f6f6;
  color: #717171;
  padding: 30px;
  -webkit-box-shadow: 0 -1px 6px #cfcfcf;
  box-shadow: 0 -1px 6px #cfcfcf;
  border-radius: 0 0 3px 3px;
}
#iubenda_policy .iub_container > .iub_footer > .btn {
  position: absolute;
  top: 25px;
  right: 30px;
}
#iubenda_policy .iub_container > .iub_footer .btn {
  padding: 0px 24px;
  line-height: 29px;
}
#iubenda_policy .iub_container > .iub_footer .button-stack {
  margin: -4px 0;
}
#iubenda_policy .iub_container > .iub_footer .button-stack .btn + .btn {
  margin-left: 5px;
}
#iubenda_policy .iub_container > .iub_footer img {
  margin: -4px 3px 0;
  vertical-align: middle;
  width: 70px;
  height: 25px;
}
#iubenda_policy .wide {
  width: 150px;
}
@media (max-width: 767px) {
  #iubenda_policy .legal_pp {
    .one_line_col,
    .half_col {
      width: 100%;

      & > ul.for_boxes > li {
        clear: both;
        width: 100%;

        &:nth-child(2n + 1) > div {
          margin-right: 0;
        }
        &:nth-child(2n) > div {
          margin-left: 0;
        }
      }
    }
  }
  #iubenda_policy .iub_header img {
    position: static;
    margin-bottom: 12.5px;
  }
  #iubenda_policy .iub_content > .iub_footer .show_comp_link {
    position: static;
    display: inline;
  }
  #iubenda_policy .iub_container > .iub_footer {
    padding: 20px;
    .btn {
      top: 15px;
      right: 15px;
    }
  }
  #iubenda_policy .iub_base_container > .close.small {
    width: 14px;
    line-height: 14px;
    border-radius: 9px;
    padding: 0;
  }
}
@media (max-width: 480px) {
  html#iubenda_policy {
    padding: 0;

    body {
      padding: 0;
    }

    .iub_base_container,
    .iub_container {
      margin: 0;
    }
    .half_col:nth-child(2n + 1) > * {
      margin-right: 0;
    }
    .half_col:nth-child(2n) > * {
      margin-left: 0;
    }
    .one_line_col,
    .half_col {
      width: 100%;

      & > ul.for_boxes > li {
        clear: both;
        width: 100%;
      }
    }
    .one_line_col > ul.for_boxes > li:nth-child(2n + 1) > div,
    .half_col > ul.for_boxes > li:nth-child(2n + 1) > div {
      margin-right: 0;
    }
  }

  #iubenda_policy .one_line_col > ul.for_boxes > li:nth-child(2n) > div,
  #iubenda_policy .half_col > ul.for_boxes > li:nth-child(2n) > div {
    margin-left: 0;
  }
  #iubenda_policy .iub_header img {
    position: static;
    margin-bottom: 12.5px;
  }
  #iubenda_policy .iub_content > .iub_footer .show_comp_link {
    position: static;
    display: inline;
  }
  #iubenda_policy .iub_container > .iub_footer {
    padding: 10px;
    text-align: center;
  }
  #iubenda_policy .iub_container > .iub_footer .btn {
    position: static;
    width: auto;
    display: block;
    margin: 10px auto 0 auto;
    max-width: 200px;
  }
  #iubenda_policy .iub_container > .iub_footer.in_preview {
    padding: 30px 10px;
  }
  #iubenda_policy .iub_content {
    padding-left: 20px;
    padding-right: 20px;
  }

  #iubenda_policy .iub_base_container > .close.small {
    width: 14px;
    line-height: 14px;
    border-radius: 9px;
    padding: 0;
  }
}
#iubenda_policy.iubenda_fixed_policy .iub_base_container {
  max-width: 800px;
}
#iubenda_policy.iubenda_fixed_policy .iub_container {
  margin-left: auto;
  margin-right: auto;
  zoom: 1;
}
#iubenda_policy.iubenda_fixed_policy .iub_container:before,
#iubenda_policy.iubenda_fixed_policy .iub_container:after {
  display: table;
  content: '';
  zoom: 1;
  *display: inline;
}
#iubenda_policy.iubenda_fixed_policy .iub_container:after {
  clear: both;
}
#iubenda_policy.iubenda_fluid_policy #wbars {
  overflow-y: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: auto;
}
#iubenda_policy.iubenda_fluid_policy .iub_container {
  margin-top: 30px;
  margin-bottom: 30px;
}
#iubenda_policy.iubenda_fluid_policy .half_col:nth-child(2n + 1) > * {
  margin-right: 0;
}
#iubenda_policy.iubenda_fluid_policy .half_col:nth-child(2n) > * {
  margin-left: 0;
}
#iubenda_policy.iubenda_fluid_policy .one_line_col,
#iubenda_policy.iubenda_fluid_policy .half_col {
  width: 100%;
}
#iubenda_policy.iubenda_fluid_policy .one_line_col > ul.for_boxes > li,
#iubenda_policy.iubenda_fluid_policy .half_col > ul.for_boxes > li {
  clear: both;
  width: 100%;
}
#iubenda_policy.iubenda_fluid_policy .one_line_col > ul.for_boxes > li:nth-child(2n + 1) > div,
#iubenda_policy.iubenda_fluid_policy .half_col > ul.for_boxes > li:nth-child(2n + 1) > div {
  margin-right: 0;
}
#iubenda_policy.iubenda_fluid_policy .one_line_col > ul.for_boxes > li:nth-child(2n) > div,
#iubenda_policy.iubenda_fluid_policy .half_col > ul.for_boxes > li:nth-child(2n) > div {
  margin-left: 0;
}
#iubenda_policy.iubenda_embed_policy .iub_base_container {
  background: none;
}
#iubenda_policy.iubenda_embed_policy .iub_container > .iub_footer {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: none;
}
#iubenda_policy.iubenda_embed_policy .expand-click {
  cursor: default;
}
#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .iub_base_container {
  color: #666;
}
#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h2 {
  font-size: 24px;
  padding-top: 50px;
}
#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h3 {
  color: #444;
  font-size: 20px;
  padding-top: 45px;
}
#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h4 {
  font-size: 16px;
  padding-top: 40px;
  color: #555;
}
#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h5 {
  font-size: 14px;
  padding-top: 35px;
  margin-bottom: 0;
  color: #666;
}
#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h6 {
  font-size: 12px;
  color: #505050;
  text-transform: uppercase;
  padding-top: 32px;
  margin-bottom: 0;
}
#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions {
  margin-top: 60px !important;
}
#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions .expand-content {
  padding: 25px 15px !important;
}
#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions .expand-content h4 {
  font-size: 15px !important;
}
#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions:before {
  content: '';
  border-top: 1px dotted rgba(0, 0, 0, 0.1);
  display: block;
  margin: 0 -10px;
  position: relative;
  top: -45px;
}
#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions h2 {
  font-size: 13px;
  color: #333;
}
#iubenda_policy.iubenda_vip_policy.iubenda_fixed_policy .iub_container {
  max-width: 660px;
  padding-top: 80px;
}
#iubenda_policy.iubenda_vip_policy .iub_base_container {
  color: #595858;
}
#iubenda_policy.iubenda_vip_policy p {
  font-size: 14px;
  line-height: 1.6;
}
#iubenda_policy.iubenda_vip_policy .allcaps,
#iubenda_policy.iubenda_vip_policy p.allcaps,
#iubenda_policy.iubenda_vip_policy ul.allcaps li {
  font-variant: small-caps !important;
  font-weight: bold !important;
  font-size: 16px !important;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Open Sans,
    Helvetica Neue,
    sans-serif !important;
}
#iubenda_policy.iubenda_vip_policy .legal_pp .one_line_col {
  padding-bottom: 50px;
}
#iubenda_policy.iubenda_vip_policy .half_col:nth-child(2n + 1) > * {
  margin-right: 0;
}
#iubenda_policy.iubenda_vip_policy .half_col:nth-child(2n) > * {
  margin-left: 0;
}
#iubenda_policy.iubenda_vip_policy .one_line_col,
#iubenda_policy.iubenda_vip_policy .half_col {
  width: 100%;
}
#iubenda_policy.iubenda_vip_policy .one_line_col > ul.for_boxes > li,
#iubenda_policy.iubenda_vip_policy .half_col > ul.for_boxes > li {
  clear: both;
  width: 100%;
}
#iubenda_policy.iubenda_vip_policy .one_line_col > ul.for_boxes > li:nth-child(2n + 1) > div,
#iubenda_policy.iubenda_vip_policy .half_col > ul.for_boxes > li:nth-child(2n + 1) > div {
  margin-right: 0;
}
#iubenda_policy.iubenda_vip_policy .one_line_col > ul.for_boxes > li:nth-child(2n) > div,
#iubenda_policy.iubenda_vip_policy .half_col > ul.for_boxes > li:nth-child(2n) > div {
  margin-left: 0;
}

#iubenda_policy.iubenda_vip_policy {
  ul li {
    font-size: 14px;
    line-height: 1.6;
  }
  h1 {
    font-size: 30px;
    color: #141414;
    line-height: 1.6;
    margin-bottom: 60px;
  }
  h2 {
    font-size: 18px;
    color: #141414;
    line-height: 1.6;
    padding-top: 50px;
    padding-bottom: 15px;
  }
  h3 {
    color: #141414;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
  }

  .legal_pp .one_line_col {
    padding-bottom: 50px;
  }

  .iub_footer,
  .for_boxes,
  .definitions {
    color: #505762;

    h3,
    p,
    li {
      font-size: 13px;
    }
  }
  .w_icon_24 {
    background-image: none;
  }
  .box_10.expand .expand-click.w_icon_24 {
    padding-left: 10px;
  }
  .box_primary {
    border-color: #e0e0e0;
    border-bottom-color: #d3d3d3;
    -webkit-box-shadow: none;
    box-shadow: none;
    h3 {
      color: #333;
    }
  }
  .tc-deactivated h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .legal_pp .one_line_col {
    padding-bottom: 21px;
  }
}
