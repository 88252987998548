*,
*:before,
*:after {
  box-sizing: border-box;
}
img,
picture,
svg,
video {
  display: block;
  width: 100%;
}
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

html {
  --border-color: #dbdee6;
  --button-border-color: #ddd;

  --card-background: hsl(0, 0%, 100%);
  --card-background-hover: hsl(0, 0%, 98%);

  --shadows-elevation2: 0px 0px 1px rgba(20, 20, 21, 0.14), 0px 4px 8px -4px rgba(20, 20, 21, 0.35);
}
body {
  min-height: 100vh;
  --font: 'Averta', 'Sora', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --headings: 'Rajdhani', 'greycliff oblique', 'Readex pro', -apple-system, BlinkMacSystemFont,
    'Helvetica Neue', sans-serif;
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
}

html,
body {
  position: relative;
  font-size: 16px;
  --primary: 248 48% 39%;
}

::selection {
  background-color: #1e3a8a; /* Tailwind's haiti color */
  color: white;
}
